<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{$t('categories.categories')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <b-card-body class="col-md-4">
                        <div class="col-lg-12 mb-5 p-0">
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="search" :placeholder="$t('search')"/>
                            </div>
                        </div>
                        <v-treeview
                                :items="tree_list"
                                :search="search"
                                :filter="filter"
                                return-object
                                selected-color="warning"
                                :selection-type="selectionType"
                                hoverable
                                expand-icon="mdi-chevron-down"
                                on-icon="mdi-bookmark"
                                off-icon="mdi-bookmark-outline"
                                indeterminate-icon="mdi-bookmark-minus"
                                :active.sync="item"
                                activatable
                        >
                            <template v-slot:prepend="{ item, open }">
                                <v-icon v-if="!item.file">
                                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                                </v-icon>
                                <v-icon v-else>
                                    {{ files[item.file] }}
                                </v-icon>
                            </template>
                        </v-treeview>
                    </b-card-body>
                    <b-card-body class="col-md-8">
                        <div class="form-group row">
                            <div class="col-lg-12 mb-5 d-flex justify-content-end">

                                <button type="button"  v-if="$can('categories.create')" class="btn btn-primary mr-2 ml-2" @click="addNewCategory">{{ $t('categories.add_new_category') }}</button>
                                <button type="button" v-if="$can('categories.create')" class="btn btn-warning mr-2 ml-2" @click="addNewSubCategory">{{ $t('categories.add_new_sub_category') }}</button>

                            </div>
                            <div class="col-lg-12 mb-5">
                                <label>{{$t('categories.name')}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" v-model="data.name" :placeholder="$t('categories.name')"/>
                                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">{{ validation.name[0] }}</span>
                                </div>
                            </div>

                            <div class="col-lg-12 mb-5">
                                <label>{{$t('categories.description')}}</label>
                                <div class="input-group">
                                    <textarea class="form-control" :class="validation && validation.description ? 'is-invalid' : ''" v-model="data.description" :placeholder="$t('categories.description')"/>
                                    <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">{{ validation.description[0] }}</span>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('categories.upload_image')}}</label>
                                <div class="form-group">
                                    <upload-file
                                            @file="listenerAttachment"
                                            :inner-id="'image'"
                                            :placeholder="$t('categories.upload_image')"
                                            :upload="dir_upload"
                                            :start-link="'base'"
                                            v-bind:valueprop="{name:'image',path:image_url}" v-if="reloadUploadAttachment">
                                    </upload-file>
                                </div>
                            </div>

                            <div class="col-lg-12 mb-5">
                                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
                            </div>
                            <div class="col-lg-12 mb-5">
                                <div class="row justify-content-end">
                                    <button type="button"  v-if="$can('categories.create') || $can('categories.update')" class="btn btn-primary mr-2 ml-2" @click="save">{{ $t('save') }}</button>
                                    <button type="button" class="btn btn-light mr-2 ml-2" @click="afterSave">{{ $t('cancel') }}</button>
                                    <button type="button"  v-if="$can('categories.delete')" :disabled="!idEdit" class="btn btn-danger mr-2 ml-2" @click="deleteItem">{{ $t('delete') }}</button>
                                </div>
                            </div>


                        </div>
                    </b-card-body>
                </div>


            </div>
<!--            <div class="card-footer">-->
<!--                <div class="row">-->
<!--                    <div class="col-lg-6">-->
<!--                        <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import Vue from "vue";

    export default {
        name: "form-categories",
        data() {
            return {
                mainRoute: 'settings/categories',
                item: [],
                selectionType: 'independent',

                tree_list: [],
                search: '',
                data: {
                    name: null,
                    description: null,
                    parent_id: null,
                    image: null,
                    is_active: true,

                },
                idEdit: null,
                image_url: null,
                dir_upload: 'categories',
                reloadUploadAttachment: true,
                isEditing: false,
                validation: null,
            };
        },
        watch: {
            item: function (val) {
                this.reloadUploadAttachment = false;
                if (val[0]) {
                    val = val[0];
                    this.idEdit = val.id;
                    this.data.name = val.name;
                    this.data.description = val.description;
                    this.data.parent_id = val.parent_id;
                    this.data.image = val.image;
                    this.data.is_active = val.is_active == 1? true: false;
                    this.image_url = val.image_url;
                }else {
                    this.idEdit = null;
                    this.data.name = null
                    this.data.description = null;
                    this.data.parent_id = null;
                    this.data.image = null;
                    this.data.is_active = true;
                    this.image_url = null;
                }
                setTimeout(()=>{
                    this.reloadUploadAttachment = true;
                }, 100);
            }
        },
        computed: {
            filter() {
                return (item, search, textKey) => item[textKey].indexOf(search) > -1
            },
        },
        methods: {
            save() {
                this.data.is_active = this.data.is_active ? 1 : 0;
                if (this.idEdit) {
                    this.update();
                } else {
                    this.create();
                }
            },
            create() {
                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.getList();
                    this.afterSave();
                }).catch((error) => {
                  Vue.prototype.$postStatus = true;
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            update() {
                ApiService.put(this.mainRoute + '/' + this.idEdit, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.getList();
                    this.afterSave();
                }).catch((error) => {
                  Vue.prototype.$postStatus = true;
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            // getData() {
            //     ApiService.get(this.mainRoute+'/get').then((response) => {
            //         this.isEditing = true;
            //         this.data = response.data.data;
            //     });
            // },
            getList() {
                ApiService.get(this.mainRoute).then((response) => {
                    this.tree_list = response.data.data;
                });
            },

            afterSave(){
                this.item = [];
                // this.reloadUploadAttachment = false;
                // this.idEdit = null;
                // this.data.name = null
                // this.data.description = null;
                // this.data.parent_id = null;
                // this.data.image = null;
                // this.data.is_active = false;
                // this.image_url = null;
                // this.reloadUploadAttachment = true;
            },
            listenerAttachment(event) {
                if (event) {
                    this.image_url = event.pathDB;
                    this.data.image = event.name;
                }
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getList();
                    this.$successAlert(response.data.message);
                    this.afterSave();
                }).catch(({errors}) => {
                    console.error(errors);
                })
            },
            deleteItem() {
                if (this.item[0]){
                    let _item = this.item[0];
                    this.$confirmAlert('', this.actionDelete, _item);
                }
            },
            addNewCategory(){
                this.reloadUploadAttachment = false;
                this.idEdit = null;
                this.data.name = null
                this.data.description = null;
                this.data.parent_id = null;
                this.data.image = null;
                this.data.is_active = true;
                this.image_url = null;

                setTimeout(()=>{
                    this.reloadUploadAttachment = true;
                }, 100);
            },
            addNewSubCategory(){
                this.reloadUploadAttachment = false;
                this.data.parent_id = this.idEdit;
                this.idEdit = null;
                this.data.name = null
                this.data.description = null;
                this.data.image = null;
                this.data.is_active = true;
                this.image_url = null;

                setTimeout(()=>{
                    this.reloadUploadAttachment = true;
                }, 100);
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Categories")}]);
            this.getList();
        },
    };
</script>

<style>
    .v-treeview-node__root{
        min-height: 37px !important;
        cursor: pointer;
    }
    .v-treeview-node--active {
        background-color: #C9F7F5;
    }
</style>
